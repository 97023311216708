<template>
    <v-container fluid>
        <v-row row wrap>
            <v-col cols="12" class="text-xs-left" ma-4>
                <h1>Join the 2019 Saguaro Society</h1>
                <p>Read about member benefits for 2019
                    <a href="http://www.wrenhousebrewing.com/society" target="_blank">here.</a>
                    Fill in the form below to purchase a membership. The email and password you enter will be used to access the member portal.
                </p>
                <h3>Member Renewal Period</h3>
             <!--   <p>There are currently {{ remaining }} memberships left.</p> -->
                <p>During this period (November 19-30), memberships are only available for purchase by existing members. Any purchase through this portal by a non-member will be cancelled. New memberships will go on sale to the public starting <strong>December 1</strong>. </p>
            </v-col>
            <v-col cols="12" ma-3>
                <v-form ref="form" @submit.prevent="purchase">
                    <v-container grid-list-md fluid>
                        <v-col>
                            <v-alert type="error" dismissible v-model="alert">
                                {{ error }}
                            </v-alert>
                        </v-col>
                        <v-row wrap>
                            <v-col cols="12" :sm="5">
                                <v-text-field 
                                    name="first"
                                    label="First Name"
                                    id="first"
                                    v-model="user.firstName"
                                    hint="Member's name (you can designate another person (a 'proxy') to pickup up pre-ordered beer at a later time"
                                    :rules="nameRules"    
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" :sm="5">
                                <v-text-field 
                                    name="last"
                                    label="Last Name"
                                    id="last"
                                    v-model="user.lastName"
                                    :rules="nameRules"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" :sm="2">
                                <v-text-field 
                                    name="memberNum"
                                    label="Member #"
                                    id="memberNum"
                                    v-model="user.memberNum"
                                    :rules="memberNumRules"    
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" :sm="6">
                                <v-text-field
                                    name="email"
                                    label="Email"
                                    id="email"
                                    type="email"
                                    v-model="user.email"
                                    hint="All member correspondence will be sent to this address. This will also serve as your user name on this site."
                                    :rules="emailRules"    
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" :sm="6">
                                <v-text-field 
                                    name="confirmEmail"
                                    label="Confirm Email"
                                    id="confirmEmail"
                                    type="email"
                                    v-model="emailConfirm"
                                    :rules="confirmEmailRules"
                                    ></v-text-field>
                            </v-col>
                            <v-col cols="12" :sm="6">
                                <v-text-field
                                    name="password"
                                    label="Password"
                                    id="password"
                                    type="password"
                                    v-model="password"
                                    :rules="passwordRules" 
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" :sm="6">
                                <v-text-field 
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    id="confirmPassword"
                                    type="password"
                                    required
                                    v-model="passwordConfirm"
                                    :rules="confirmPasswordRules"
                                    ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-checkbox
                                v-model="termsAccepted"
                                >
                                    <div slot="label" @click.stop="">
                                        Do you accept the
                                        <a href="javascript:;" @click.stop="terms = true">terms and conditions</a>?
                                    </div>
                                </v-checkbox>
                            </v-col>
                            <v-col center cols="12">
                                <SquareCart
                                    v-show="readyForPayment"
                                    v-bind:cart="cart"
                                    v-bind:showTitle="showTitle"
                                    v-bind:showDetails="showDetails"
                                />
                            </v-col>
                            <v-col center cols="12">
                                <form action="userSignUp">
                                    <Square 
                                        v-show="readyForPayment" 
                                        v-bind:buttonText="purchaseButtonText"
                                        v-bind:showPaymentForm=readyForPayment 
                                        @payment-successful="userSignUp"/>
                                </form>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-col>
            <v-dialog v-model="terms" width="70%">
                <v-card>
                    <v-card-title class="title">Terms and Conditions</v-card-title>
                    <v-card-text>
                        <ul>
                            <li v-for="line in termsContent" v-bind:key=line>{{ line }}</li>
                        </ul>
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="purple"
                        @click="terms = false; termsAccepted = true"
                    >I agree</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import '@/settings'
import Square from '@/components/payment/SquareCheckout'
import SquareCart from '@/components/payment/SquareCart'
import axios from 'axios'
import { mapMutations } from 'vuex'
import api from '@/store/api'
export default {
    data () {
        return {
            user: {  //this creates the database structure for user in fb
                uid: '', //filled in by auth api
                firstName: '',
                lastName: '',
                memberNum: '',
                email: '',
                orders: [],
                proxy: '',
                member2017: false,
                member2018: true,
                member2019: true,
                userType: "saguaro",
                isAdmin: false //only way to make true is direct edit of database by admin
            },
            emailConfirm: '',
            password: '', //passed into firebase auth along with user info
            passwordConfirm: '', 
            remaining: 17,  //TODO update to show actual number of memberships remaining
            alert: false,
            payment: false,
            checkout: false,
            order: null,
            showTitle: false,
            showDetails: false,
            purchaseButtonText: "Purchase Membership",
            terms: false,
            termsAccepted: false,
            termsContent: [
                "Payment of membership fee through this portal by a member of the 2018 Saguaro Society entitles the purchaser to membership and member benefits for the 2019 Saguaro Society",
                "Member benefits are those published at www.wrenhousebrewing.com/society",
                "Yearly membership fee of $150 plus sales tax is due at time of purchase and is non-refundable.",
                "Membership benefits are valid from January 1, 2019 through December 31, 2019.",
                "One person per membership and one membership per person.",
                "Wren House reserves the right to terminate and refund the unused portion of any membership for tomfoolery, shenanigans, or a general breaking of the rules.",
            ],
            nameRules: [
                v => !!v || 'Required',
                v => (v && v.length <= 15) || 'Must be less than 15 characters'
            ],
            memberNumRules: [
                v => !!v || 'Required',
                v => (!isNaN(v) && v > 0 && v < 220) || 'Invalid #'
            ],
            emailRules: [
                v => !!v || 'Required',
                v => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
                v => this.emailIsTaken(v) || 'Email address is already in use by another account'            
            ],
            confirmEmailRules: [
                v => !!v || 'Required',
                v => (v === this.user.email) || 'Emails don\'t match'
            ],
            passwordRules: [
                v => !!v || 'Required',
                v => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(v) || 'Must be at least 6 characters with at least 1 lowercase letter, 1 uppercase letter, and 1 number'
            ],
            confirmPasswordRules: [
                v => !!v || 'Required',
                v => (v === this.password) || 'Passwords don\'t match'
            ],
        }
    },
    mounted: function () {  //create the product order and when returns, store in state
        let self = this
        const functionServer = ENV.functionsServer()
        const squareLineItemToBuild = { 
            name: "Saguaro Society Membership",
            variation_name: "Annual Membership Fee",
            quantity: "1",
            priceInCents: 15000,
            //by default additive phx az 8.6 tax is added
        }
        return new Promise( function(resolve) {
            axios.post(functionServer + "createsquareorder", squareLineItemToBuild)
            .then( function(response) {
                const order = response.data.order //this starts as a square order, now add some details for local use
                order.name = "Society Membership 2019"
                order.release = "January 1, 2019"
                order.deadline = "December 31, 2019"
                order.status = "In Cart"
                self.addSquareOrderToCart(order)
            //    console.log(order)
                //need to pass order.id and order.total_money back to paysquare to process payment
                resolve(response);
            });
        });     
    },
    components:   {
        Square,
        SquareCart
    },
    computed: {
        readyForPayment () {  //payment form is only showed if everything else is entered, valid. submitting payment form submits all
            if (this.termsAccepted && this.$refs.form.validate())
                return true
            return false
        },
        cart () { return this.$store.state.cart },
        error () { return this.$store.state.error },
        loading () { return this.$store.state.loading }
    },
    methods: {
        ...mapMutations([
            'addSquareOrderToCart'
        ]),
        emailIsTaken (email) {
            //this method is necessary because we only want to check with firebase auth if an email is in use, after the other validation methods have passed
            //in the simple list of rules, each rule is called with every keystroke
            const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if(regex.test(email)){
                return api.emailInUse(email)
            }
            return false 
        },
        userSignUp () {
            //all validity checks have been done previously. execute assuming everything is in place
            console.log("entered user signup")
            //record user info, authenticate, and redirect to welcome screen
            this.$store.dispatch('userSignUp', { user: this.user, password: this.password })
        }
    },
    watch: {
        error (value) {
            if (value) {
                this.alert = true
            }
        },
        alert (value) {
            if (!value) {
                this.$store.commit('setError', null)
            }
        }
    }
}
</script>